.OnBoarding {
  .firstStepOnboarding {
    .MuiGrid-item:not(:first-child) {
      padding-top: 30px;
    }
  }

  .secondStepOnboarding {
    .MuiGrid-item:not(:first-child) {
      padding-top: 30px;
    }
  }
}
