.DistributionActivity {
  padding: 10px;
  border-radius: $border-radius-s;
  flex: 1;

  .logo {
    img {
      margin-bottom: 20px;
    }
  }

  .text {
    p {
      color: black;
      font-size: 11px;
    }

    h3 {
      font-size: 15px;
    }
  }
}
