.ActivityReport {
  .buttonGroupe {
    width: $full-width;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    .MuiButtonGroup-firstButton {
      @include themed($themes) {
        border-color: t("text-color-secondary");
      }
    }

    .MuiButtonBase-root {
      width: $full-width;
      text-transform: none;
      font-size: 12px;
      @include themed($themes) {
        color: t("text-color-secondary");
      }
    }

    .active {
      color: $primary !important;
    }
  }

  .black-text {
    @include themed($themes) {
      color: t("text-color-primary");
    }
  }

  .bold-text {
    font-weight: 500 !important;
  }
}
