.FrenquencyButton {
  height: 25px;
  font-size: 12px !important;
  @include themed($themes) {
    color: t("text-color-secondary");
    background: t("button-background");
    border: 1px solid t("border") !important ;
  }
  text-transform: none !important;
  border-radius: $border-radius-m !important;
}
