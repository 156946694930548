.Warning {
  border-radius: $border-radius-s;

  .MuiPaper-root {
    padding: 6px;

    .MuiAlert-message {
      font-size: 10px;
    }
  }
}
