//https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

/*Typo*/
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* abstract */
@import "abstracts/variables";
@import "abstracts/mixins";

//base

/* themes */
@import "theme/theme-config";

/* components */
// -> atoms
@import "components/atoms/StyledButton/StyledButton";
@import "components/atoms/Title/Title";
@import "components/atoms/Text/Text";
@import "components/atoms/ProfilePicture/ProfilePicture";
@import "components/atoms/Warning/Warning";
@import "components/atoms/StyledPaper/StyledPaper";
@import "components/atoms/Doughnut/Doughnut";
@import "components/atoms/DoughnutActivityDistribution/DoughnutActivityDistribution";
@import "components/atoms/DistributionActivity/DistributionActivity";
@import "components/atoms/FrenquencyButton/FrenquencyButton";
@import "components/atoms/BarChart/BarChart";
@import "components/atoms/SpinnerLoader/SpinnerLoader";
@import "components/atoms/CustomSkeleton/CustomSkeleton";

// -> molecules
@import "components/molecules/HomeDailyGoal/HomeDailyGoal";
@import "components/molecules/Insurance/Insurance";
@import "components/molecules/SeeMore/SeeMore";
@import "components/molecules/DataComparator/DataComparator";
@import "components/molecules/DisplayActivityDistribution/DisplayActivityDistribution";
@import "components/molecules/FrenquencyChoice/FrenquencyChoice";
@import "components/molecules/HomeActivityDistribution/HomeActivityDistribution";

// -> organisme

// -> pages
@import "components/pages/ErrorPage/ErrorPage";
@import "components/pages/OnBoarding/OnBoarding";
@import "components/pages/AimedGoal/AimedGoal";
@import "components/pages/ActivityReport/ActivityReport";
@import "components/pages/ActivityDistribution/ActivityDistribution";

// -> templates
@import "components/templates/Header/Header";
@import "components/templates/Container/Container";

/* layout */

/* global */

body {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  font-family: "Lato";
  font-size: 12px;
  transition: all 0.5s linear;
  overflow-x: hidden;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.App {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  // padding: "0px 20px";
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  background-color: #f9f8f8 !important;
}
