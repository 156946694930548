.BarChart {
  display: flex;
  max-width: calc(100vw - 40px);

  .colSmall {
    width: 30px;
  }

  .colLarge {
    max-width: calc(100vw - 40px);
    overflow-x: scroll;

    .box {
      width: calc(100vw - 70px);
    }
  }

  canvas {
    height: 250px !important;
  }
}
