.ErrorPage {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  width: $full-width;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  align-items: center;
  padding: 50px;

  .textErrorPage {
    text-align: center !important;
  }

  svg {
    margin: 50px 0px;
  }

  a {
    width: -webkit-fill-available;
  }
}
