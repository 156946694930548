.Insurance {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: $primary-gradient;
  border-radius: $border-radius-m;
  box-shadow: 0px 5px 9px 0px rgba(43, 46, 55, 0.1),
    0px -2px 6px 0px rgba(43, 46, 55, 0.08);

  .MuiTypography-root {
    color: white !important;
    text-align: center;
    margin-bottom: 20px;
  }

  button {
    background: $secondary-gradient !important;
    color: $primary;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
  }
}
