.FrenquencyChoice {
  .MuiGrid-item {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .active {
    background: $primary !important;
    color: white;
  }
}
