.Doughnut {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 250px;

  canvas {
    position: absolute;
  }

  .children {
    max-width: 250px;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h3 {
      @include themed($themes) {
        color: t("text-color-tertiary");
      }
    }
    svg {
      @include themed($themes) {
        fill: t("text-color-tertiary");
      }
    }
  }
}
