.SpinnerLoader {
  width: $full-width;
  height: $full-height;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-root {
    color: $primary;
  }
}
