.DisplayActivityDistribution {
  align-items: center;
  margin-top: -10px !important;

  .logo {
    width: 30px;
    height: 30px;
    background-color: $secondary-gradient;
    border-radius: $border-radius-s;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .MuiLinearProgress-root {
    background-color: #e8e8e8;
    height: 10px;
    border-radius: $border-radius-s;

    .MuiLinearProgress-bar {
      border-radius: $border-radius-s;
    }
  }

  .alignText {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .MuiTypography-root {
      font-size: 12px;
    }
  }
}
