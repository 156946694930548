.SeeMore {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .arrow {
    @include themed($themes) {
      color: t("text-color-secondary");
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    a {
      width: 100%;
    }

    .MuiSvgIcon-root {
      font-size: 16px;
      margin-left: 5px;
    }

    .MuiTypography-root {
      font-size: 11px;
    }
  }
}
