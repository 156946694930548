.Text {
  text-align: center;

  p {
    @include themed($themes) {
      color: t("text-color-secondary");
    }
    text-align: start;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
  }

  span {
    text-align: start;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
  }
}
