.ActivityDistribution {
  .black-text {
    @include themed($themes) {
      color: t("text-color-primary");
    }
  }

  .bold-text {
    font-weight: 500 !important;
  }
}
