.HeaderTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $full-width;
  @include themed($themes) {
    background-color: t("pagesBackgroundColor");
  }

  .header {
    width: $full-width;
    height: 50px;
    border-bottom: 2px solid $primary;
    display: flex;
    align-items: center;
    padding-top: 5px;
    top: 0;
    position: sticky;
    @include themed($themes) {
      background-color: t("pagesBackgroundColor");
    }
    z-index: 1;

    .title,
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-left: 36px;
    }

    .arrowColor {
      color: $primary;
    }
  }
}
