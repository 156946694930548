.AimedGoal {
  .avatar {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-self: center;
    background: $secondary-gradient;

    svg {
      fill: $primary;
      margin-top: 5px;
    }
  }
}
