.StyledPaper {
  @include themed($themes) {
    background: t("paper");
    color: t("text-color-primary");
    border: t("borderPaper");
  }
  padding: 15px;
  box-shadow: none !important;
  border-radius: 6px !important;
}
