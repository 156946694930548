.DataComparator {
  .title {
    .MuiTypography-root {
      @include themed($themes) {
        color: t("title-data");
      }
    }
  }
  .MuiLinearProgress-root {
    max-width: 75%;
    background-color: transparent;
    height: 18px;
    border-radius: $border-radius-s;

    .MuiLinearProgress-bar {
      //   background: $primary-gradient !important;
      border-radius: $border-radius-s;
    }
  }
  .darkBar {
    .MuiLinearProgress-bar {
      background: $primary-gradient;
    }
  }

  .lightBar {
    .MuiLinearProgress-bar {
      background: $secondary-gradient;
    }
  }

  .toLowerCase {
    font-size: 12px;
  }
}
