.StyledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .MuiButtonBase-root {
    background: $primary-gradient;
    border-radius: 40px;
    width: 100%;
    max-width: 260px;
    height: 45px;
    text-transform: none;
    box-shadow: none;
  }
}
